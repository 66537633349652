<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
export default {
  components: { Swiper, SwiperSlide },
  props: {
    items: {
      required: true,
      type: Array
    },
    field: {
      type: String,
      default: ""
    }
  },
  data: () => ({
    newFile: null,
    swiperOptions: {
      slidesPerView: "auto",
      centeredSlides: true,
      spaceBetween: 30,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev"
      }
    }
  }),
  methods: {
    remove(item) {
      this.$emit(`onRemove`, item);
    },
    fileChanged(event) {
      var reader = new FileReader();
      reader.onloadend = () => {
        let file = {
          filename: event.target.files[0].name
        };
        file.base64File = reader.result;
        this.$emit(`onFileChanged`, file);
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  }
};
</script>

<template>
  <b-card>
    <b-row>
      <b-col class="text-center">
        <h2>Image Collection</h2>
      </b-col>
    </b-row>
    <hr />
    <b-card-title>
      <b-form-file
        v-model="newFile"
        class="ml-auto"
        placeholder="Upload more Image..."
        drop-placeholder="Drop image here..."
        accept="image/jpeg, image/png, image/jpg"
        @change="fileChanged"
      ></b-form-file>
    </b-card-title>
    <!-- <b-carousel ref="myCarousel" v-model="curIdx" :interval="4000" controls indicators>
      <b-carousel-slide v-for="(item, index) in items" :key="index" :img-src="item[field]" />
    </b-carousel> -->

    <swiper ref="mySwiper" class="swiper-centered-slides" :options="swiperOptions">
      <swiper-slide v-for="(item, index) in items" :key="index" class="rounded swiper-shadow">
        <div><b-img :src="item.fileUrl" width="200" /></div>
        <div class="mt-2">
          <b-button variant="danger" @click.stop="remove(item)">
            <span>Remove</span>
            <feather-icon icon="TrashIcon" class="text-light ml-1" />
          </b-button>
        </div>
      </swiper-slide>
      <div slot="button-next" class="swiper-button-next" />
      <div slot="button-prev" class="swiper-button-prev" />
    </swiper>
  </b-card>
</template>

<style lang="scss">
@import "@core/scss/vue/libs/swiper.scss";
</style>
